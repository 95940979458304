import React, {useState, useContext, useEffect} from 'react'
import loginimg from '../img/login2.png';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { AuthContext } from '../context/AuthContext';
import { Link } from 'react-router-dom';


export default function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const {login, isLoading} = useContext(AuthContext);
    const [isFormFilled, setIsFormFilled] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const whereToGo = localStorage.getItem('pathName');

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (whereToGo !== '/esim' && whereToGo !== '/simcard' && whereToGo !== '/wifi' && whereToGo !== '/power-bank') {
            localStorage.setItem("pathName", window.location.pathname);
        }        
        
        login(username, password, e);
    }

    useEffect(() => {
        setIsFormFilled(username !== '' && password !== '');
    }, [username, password]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit(e);
        }
    };
  return (
    <div className="form-inner d-flex justify-content-center align-items-center">
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        <div className="container login-container" style={{ maxWidth: "400px" }}>
            <header className=" imgLogo" >
                <img src={loginimg} alt="logo" />
            </header>
            <h2 className="title">Login</h2>
            <form onSubmit={handleSubmit} >
                <TextField
                    type='email'
                    label="Username"
                    placeholder='Username'
                    variant="outlined"
                    fullWidth
                    required
                    className='mb-3'
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                
                <TextField 
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    placeholder="Password" 
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                    endAdornment: 
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    }}
                    
                    variant="outlined" 
                    required
                    fullWidth
                    className="mb-3"
                    onKeyDown={handleKeyDown}
                />

                <Button disabled={!isFormFilled} variant="contained" type='submit'  fullWidth className="mb-3">Login</Button>
            </form>
            <div className="text-center">
                <Link to="/password-reset" className="text-decoration-none">Forgot Password?</Link>
            </div>
            <div className="text-center">
                <span>Don't have an account?</span><Link to="/register" className="text-decoration-none">Register</Link>
            </div>
            
        </div>
    </div>
  )
}
