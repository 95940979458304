import * as React from 'react';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import CallIcon from '@mui/icons-material/Call';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import payment from '../img/paystack-badge-cards-ghs.svg'

export const Footer = () =>{
  // get current year 
  const year = new Date().getFullYear();

  return (
    <div style={{backgroundColor: "#00239F"}}>
      <div className="container footer py-5 mt-4" > 
          {/* <div className="py-5" >
            <h4 className='text-center' >Subscribe to our newsletter</h4>
            <div className="d-flex justify-content-center align-items-center ">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-12 col-md-4">
                    <div className="d-flex">
                      <input className="form-control" type="email" placeholder="Enter your email" />
                      <button className="btn btn-outline-light ms-1">Subscribe</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
            <div className="row" >
              <div className="col-sm-4" >
                <h4>Address</h4>
                <p className="mb-0">NII SAI GBEHE LN</p>
                <p className="mb-0">GD-184-1768</p>
                <p>AMERICAN HOUSE, EAST LEGON</p>
              </div>
              <div className="col-sm-4" >
                <h4>Quick Links</h4>
                  <p className="mb-0"> <a href="/selfservice/esim">E-Sim </a> </p>
                  <p className="mb-0"> <a href="/selfservice/simcard">Sim Card </a> </p>
                  <p > <a href="/top-up-data">Data Top-Up </a> </p>
              </div>
              <div className="col-sm-4" >
                <div className="social-icons" >
                <h4>Contact Us</h4>
                    <p className="mb-0" > <span><CallIcon  style={{color: "#fff"}} /></span> <a href="tel:+233206983015" target="_blank" rel="noreferrer">+233 20 698 3015</a></p>
                    <p className="mb-0" > <span><WhatsAppIcon  style={{color: "#fff"}} /></span> <a href="https://wa.me/0243816162" target="_blank" rel="noreferrer">+233 243 816 162</a></p>
                    <p> <span><EmailIcon style={{color: "#fff"}} /></span> <a href="mailto:info@rentsimconnect.com" target="_blank" rel="noreferrer">info@rentsimconnect.com</a></p>
                </div>
              </div>
              <hr style={{color: 'white'}} ></hr>
            </div>
            <div className='row align-items-center justify-content-between ' >
                <div className="col-sm-6" >
                  <h6>&copy; {year} Buy SIM & Connect GH - All Rights Reserved</h6>
                  <h6>Developed by <a className='text-white' href="https://techieszon.com/" target="_blank" rel="noreferrer">Techieszon</a></h6>
                </div>
                <div className="col-sm-2" >
                  <a href="https://www.instagram.com/rentsim_connectsgh/" target="_blank" rel="noreferrer"> <InstagramIcon style={{color: "#fff", width:30, height:35}} /> </a>
                  <a href="https://web.facebook.com/profile.php?id=100089488281297" target="_blank" rel="noreferrer"> <FacebookIcon style={{color: "#fff", width:30, height:35}} /> </a>
                  <a href="https://twitter.com/rentsim_connect" target="_blank" rel="noreferrer"> <img src='https://img.icons8.com/ios-filled/30/ffffff/twitterx--v1.png'  alt='X' style={{width:30}} /> </a>
                </div>
                <div className='col-sm-4' >
                  <img src={payment} alt='Payment Methods' className='img-fluid bg-white rounded-3' />
                </div>
            </div>
      </div>
    </div>
    
  );
}