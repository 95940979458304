import React, { useContext, useState, useEffect } from 'react';
import logo from '../img/logoheader.png';
import { AuthContext } from '../context/AuthContext';
import { useLocation } from 'react-router-dom';

export default function NavBars() {
  const token = sessionStorage.getItem('token');
  const { logout } = useContext(AuthContext);
  const location = useLocation();

  // State to track scroll position
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className={`navbar navbar-expand-lg py-3 navbar-dark shadow sticky-top ${scrolled ? "scrolled-navbar" : "bg-light"}`}>
      <div className="container">
        <a className="navbar-brand d-flex align-items-center" href="/">
          <img src={logo} alt="logo" className="logohead" />
          <span className='ms-1 me-2 text-dark'> <h4 className='fw-bold mb-0' >RentSimConnect</h4> </span>
        </a>
        <button className="navbar-toggler bg-primary border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className={`nav-link ${location.pathname === "/" ? "active" : ""}`} href="/">Home</a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${location.hash === "#about" ? "active" : ""}`} href="./#about">
                About Us
              </a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${location.hash === "#pricing" ? "active" : ""}`} href="./#pricing">
                Pricing
              </a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${location.pathname === "/selfservice/esim" ? "active" : ""}`} href="/selfservice/esim">
                E-Sim
              </a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${location.pathname === "/selfservice/simcard" ? "active" : ""}`} href="/selfservice/simcard">
                Sim Card
              </a>
            </li>
            {/* <li className={`nav-item dropdown ${["/esim", "/simcard"].includes(location.pathname) ? "active" : ""}`}>
              <button className={`nav-link dropdown-toggle ${["/esim", "/simcard"].includes(location.pathname) ? "active" : ""}`} data-bs-toggle="dropdown" aria-expanded="false">
                Sims
              </button>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" href="/esim">E-Sim</a></li>
                <li><a className="dropdown-item" href="/simcard">Sim Card</a></li>
              </ul>
            </li> */}
            {/* <li className={`nav-item dropdown ${["/wifi", "/power-bank"].includes(location.pathname) ? "active" : ""}`}>
              <button className={`nav-link dropdown-toggle ${["/wifi", "/power-bank"].includes(location.pathname) ? "active" : ""}`} data-bs-toggle="dropdown" aria-expanded="false">
                Electronic Devices
              </button>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" href="/wifi">Wifi Device</a></li>
                <li><a className="dropdown-item" href="/power-bank">Power Bank</a></li>
              </ul>
            </li> */}
            <li className="nav-item">
              <a className={`nav-link ${location.pathname === "/top-up-data" ? "active" : ""}`} href="/top-up-data">
                Data Top-Up
              </a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${location.pathname === "/top-up-airtime" ? "active" : ""}`} href="/top-up-airtime">
                Airtime Top-Up
              </a>
            </li>
          </ul>
          <div className='d-flex'>
            <>
              {token ?
                <div>
                  <a href="/dashboard">
                    <button className='btn btn-outline-primary'>Dashboard</button>
                  </a>
                  <button className='btn btn-outline-dark ms-2' onClick={logout}>Logout</button>
                </div>
                :
                <div className='d-flex justify-content-between'>
                  <div className="dropdown">
                    <button className="btn btn-outline-primary dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                      Self Service
                    </button>
                    <ul className="dropdown-menu">
                      <li><a className="dropdown-item" href="/selfservice/esim">E-SIM</a></li>
                      <li><a className="dropdown-item" href="/selfservice/simcard">SIM Card</a></li>
                      <li><a className="dropdown-item" href="/selfservice/esim/international">International E-SIM</a></li>
                      <li><a className="dropdown-item" href="/selfservice/wifi">Wifi Device</a></li>
                    </ul>
                  </div>
                  <a href="/login" className='btn btn-outline-dark ms-2'>Login</a>
                </div>
              }
            </>
          </div>
        </div>
      </div>
    </nav>
  );
}