import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import { HomeScreen } from "./screens/HomeScreen";
import Register from "./screens/Register";
import Login from "./screens/Login";
import PasswordReset from "./screens/PasswordReset";
import Otp from "./screens/Otp";
import NewPassword from "./screens/NewPassword";
import Dashboard from "./screens/Dashboard";
import SelfService from "./screens/SelfService";
import SimCard from "./screens/SimCard";
import Esim from "./screens/Esim";
import Wifi from "./screens/Wifi";
import PowerBank from "./screens/PowerBank";
import { Onboarding } from "./screens/Onboarding";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import SelfServiceEsim from "./screens/SelfServiceEsim";
import SelfServiceSimCard from "./screens/SelfServiceSimCard";
import SelfServicePowerBank from "./screens/SelfServicePowerBank";
import SelfServiceWifi from "./screens/SelfServiceWifi";
import QrCodeInstall from "./screens/QrCodeInstall";
import TopUpPackage from "./screens/TopUpPackage";
import TopUpCheckout from "./screens/TopUpCheckout";
import AirtimeTopUp from "./screens/AirtimeTopUp";
import InterESims from "./screens/InterESims";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/selfservice/checkout/:id" element={<SelfService />} />
        <Route path="/selfservice/esim" element={<SelfServiceEsim />} />
        <Route path="/selfservice/esim/international" element={<InterESims />} />
        <Route path="/selfservice/simcard" element={<SelfServiceSimCard />} />
        <Route path="/selfservice/power-bank" element={<SelfServicePowerBank />} />
        <Route path="/selfservice/wifi" element={<SelfServiceWifi />} />
        <Route path="/qrcode/install" element={<QrCodeInstall />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/otp" element={<Otp />} />
        <Route path="/new-password" element={<NewPassword />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/simcard" element={<SimCard />} />
        <Route path="/esim" element={<Esim />} />
        <Route path="/wifi" element={<Wifi />} />
        <Route path="/power-bank" element={<PowerBank />} />
        <Route path="/checkout/:id" element={<Onboarding />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/top-up-data" element={<TopUpPackage />} />
        <Route path="/top-up-airtime" element={<AirtimeTopUp />} />
        <Route path="/top-up-checkout/:id" element={<TopUpCheckout />} />
      </Routes>
    </div>
  );
}

export default App;
