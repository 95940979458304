import React, { useContext, useEffect, useState } from 'react'
import NavBars from "../components/NavBars";
import { AuthContext } from '../context/AuthContext';
import { Backdrop, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { Footer } from "../components/Footer";
import { ArrowLeft, ArrowRight } from '@mui/icons-material';

function SelfServiceEsim() {
    const { productData } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true)
    const [showNoProductFound, setShowNoProductFound] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 8;

    const products = productData;
    const filteredProducts = products.filter(product => product.name.toLowerCase().includes("e-sim"));

    const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

    const currentProducts = filteredProducts.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    useEffect(() => {
        let timeoutId;

        if (filteredProducts.length === 0) {
            timeoutId = setTimeout(() => {
                setIsLoading(false);
                setShowNoProductFound(true);
            }, 3000);
        } else {
            setIsLoading(false);
        }

        return () => clearTimeout(timeoutId);
    }, [filteredProducts]);

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prev => prev + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(prev => prev - 1);
        }
    };

    return (
        <div>
            <NavBars />
            <div className='container mt-5'>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <h2>E-Sim</h2>
                {!isLoading && showNoProductFound && <h4 className='text-center'>No product found</h4>}
                <div className="row">
                    {currentProducts.map(product => (
                        <div key={product.id} className="col-sm-3 mb-3">
                            <div className="card mb-3 shadow product border-0">
                                    <img src={product.image} className="img-fluid p-2" alt={product.name} />
                                    <div className="card-body d-flex flex-column">
                                        <h6 className="card-title fw-bold text-center">{product.name.replace(/\s\d+$/, '')}</h6>
                                        <p className="card-text text-center flex-grow-1">{product.description}</p>
                                        <p className="card-text text-center fw-bold">${(product.price * product.duration).toFixed(2)}</p>
                                        <div className='text-center'>
                                            <Link to={{ pathname: `/selfservice/checkout/${product.id}` }} className="btn btn-outline-primary w-50 ">Buy</Link>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="d-flex justify-content-center mt-4">
                    <button 
                        className="btn btn-outline-primary mx-2" 
                        onClick={handlePrevious} 
                        disabled={currentPage === 1}
                    >
                        <ArrowLeft/>
                    </button>
                    <button 
                        className="btn btn-outline-primary mx-2" 
                        onClick={handleNext} 
                        disabled={currentPage === totalPages}
                    >
                        <ArrowRight/>
                    </button>
                </div>
            </div>
            <div className="">
                <Footer />
            </div>
        </div>
    );
}

export default SelfServiceEsim;