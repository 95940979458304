import React, {useContext, useEffect} from "react";
import { Link, useLocation } from 'react-router-dom';
import NavBars from "../components/NavBars";
import ad from "../vdu/video.mp4";
import hero from "../img/hero.png"
import rentimg from "../img/rent_12.png";
import customerReviews from "../vdu/review.mp4";
import customerReviews2 from "../vdu/review2.mp4";
import sim1 from "../img/sim1.jpg";
import sim2 from "../img/sim2.jpg";
import sim3 from "../img/sim3.jpg";
import sim4 from "../img/sim4.jpg";
import sim5 from "../img/sim5.jpg";
import sim6 from "../img/sim6.jpg";
import sim7 from "../img/sim7.jpg";
import sim8 from "../img/sim8.jpg";
import sim9 from "../img/sim9.jpg";
import sim10 from "../img/sim10.jpg";
import esim1 from "../img/E-simoneweek.jpg";
import esim2 from "../img/E-simtwoweeks.jpg";
import esim3 from "../img/E-simthreeweeks.jpg";
import esim4 from "../img/E-simonemonth.jpg";
import esim5 from "../img/3gbesimparkage.jpg";
import esim6 from "../img/1.5gbesimparkage.jpg";
import wifi1 from "../img/mifi/5.jpeg";
import wifi2 from "../img/mifi/1.jpeg";
import wifi3 from "../img/mifi/2.jpeg";
import wifi4 from "../img/mifi/3.jpeg";
import wifi5 from "../img/mifi/4.jpeg";
import powerBank1 from "../img/powerbanks/1.jpeg";
import powerBank2 from "../img/powerbanks/2.jpeg";
import powerBank3 from "../img/powerbanks/3.jpeg";
import powerBank4 from "../img/powerbanks/4.jpeg";
import powerBank5 from "../img/powerbanks/5.jpeg";
import Button from '@mui/material/Button';
import { Footer } from "../components/Footer";
import { AuthContext } from "../context/AuthContext";
import { CheckCircleOutline } from "@mui/icons-material";


export const HomeScreen = () => {
  const location = useLocation();
  const {productData} = useContext(AuthContext);
  const products = productData;
  const filteredProducts = products
  .filter(product => product.name.toLowerCase().includes("data top-up"))
  .slice(0, 4);


  useEffect(() => {
    if (location.hash === '#pricing') {
      const pricingSection = document.getElementById('pricing');
      if (pricingSection) {
        pricingSection.scrollIntoView();
      }
    } else if (location.hash === '#about'){
      const aboutSection = document.getElementById('about');
      if(aboutSection){
        aboutSection.scrollIntoView();
      }
    }else if (location.hash === '#contact'){
      const contactSection = document.getElementById('contact');
      if(contactSection){
        contactSection.scrollIntoView();
      }
    }
  }, [location]);

  return (
    <div >
        <NavBars/>
        <div className="container mb-5 mt-5" id="home">
          <div className="banner ">
            <div className="row mb-4">
              <div className="col-md-7">
                <div className="mb-5">
                <div className="banner-text">
                  <h1>Experience seamless connectivity with RentSimConnect</h1>
                </div>
                <p>Unlimited Internet & local Calls with RentSimConnect</p>
                <div className="banner-btn">
                  <a href="/register"><Button variant="contained" style={{backgroundColor: "#00239F", color: "#ffffff", fontWeight: "bolder"}}>Get Connected</Button></a>
                </div>
              </div>
              
              </div>
              <div className="col-md-5">
                <div className="banner-img">
                  <img src={hero} alt="hero" className="img-fluid"  />
                </div>
              </div>
            </div>
            <div className="banner-video" >
              <video src={ad} autoPlay loop muted  ></video>
            </div>
            
          </div>
          <div className="packages mb-5">
            <div className="container">
            <h2 className="text-center mt-5" >Products</h2>
              <div className="row">
                <div className="col-sm py-4 pb-3">
                  <div className="card shadow service border-0" style={{ borderRadius: 10 }}>
                    <div id="carouselExampleAutoplaying3" className="carousel slide" data-bs-ride="carousel">
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <img src={sim1} className="d-block w-100" alt="..." style={{ borderRadius: 10 }}/>
                        </div>
                        <div className="carousel-item">
                          <img src={sim2} className="d-block w-100" alt="..." style={{ borderRadius: 10 }}/>
                        </div>
                        <div className="carousel-item">
                          <img src={sim3} className="d-block w-100" alt="..." style={{ borderRadius: 10 }}/>
                        </div>
                        <div className="carousel-item">
                          <img src={sim4} className="d-block w-100" alt="..." style={{ borderRadius: 10 }}/>
                        </div>
                        <div className="carousel-item">
                          <img src={sim5} className="d-block w-100" alt="..." style={{ borderRadius: 10 }}/>
                        </div>
                        <div className="carousel-item">
                          <img src={sim6} className="d-block w-100" alt="..." style={{ borderRadius: 10 }}/>
                        </div>
                        <div className="carousel-item">
                          <img src={sim7} className="d-block w-100" alt="..." style={{ borderRadius: 10 }}/>
                        </div>
                        <div className="carousel-item">
                          <img src={sim8} className="d-block w-100" alt="..." style={{ borderRadius: 10 }}/>
                        </div>
                        <div className="carousel-item">
                          <img src={sim9} className="d-block w-100" alt="..." style={{ borderRadius: 10 }}/>
                        </div>
                        <div className="carousel-item">
                          <img src={sim10} className="d-block w-100" alt="..." style={{ borderRadius: 10 }}/>
                        </div>
                      </div>
                      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying3" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying3" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>
                    <div className="card-body text-center">
                      <h5 className="card-title">SIM Card Sale</h5>
                      <p className="card-text">Buy a SIM card for your stay in Ghana.</p>
                      <a href="/selfservice/simcard" className="btn btn-primary ">More</a>
                    </div>
                  </div>
                  
                </div>
                <div className="col-sm py-4">
                  <div className="card shadow service border-0" style={{ borderRadius: 10 }}>
                    <div id="carouselExampleAutoplaying3" className="carousel slide" data-bs-ride="carousel">
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <img src={esim1} className="d-block w-100" alt="..." style={{ borderRadius: 10 }}/>
                        </div>
                        <div className="carousel-item">
                          <img src={esim2} className="d-block w-100" alt="..." style={{ borderRadius: 10 }}/>
                        </div>
                        <div className="carousel-item">
                          <img src={esim3} className="d-block w-100" alt="..." style={{ borderRadius: 10 }}/>
                        </div>
                        <div className="carousel-item">
                          <img src={esim4} className="d-block w-100" alt="..." style={{ borderRadius: 10 }}/>
                        </div>
                        <div className="carousel-item">
                          <img src={esim5} className="d-block w-100" alt="..." style={{ borderRadius: 10 }}/>
                        </div>
                        <div className="carousel-item">
                          <img src={esim6} className="d-block w-100" alt="..." style={{ borderRadius: 10 }}/>
                        </div>
                      </div>
                      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying3" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying3" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>
                    <div className="card-body text-center">
                      <h5 className="card-title">E-SIM Sale</h5>
                      <p className="card-text">Buy an E-SIM for your stay in Ghana.</p>
                      <a href="/selfservice/esim" className="btn btn-primary">More</a>
                    </div>
                  </div>
                  
                </div>
                <div className="col-sm py-4">
                    <div className="card shadow service border-0" style={{ borderRadius: 10 }}>
                    <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <img src={wifi1} className="d-block w-100" alt="..." style={{ borderRadius: 10 }}/>
                        </div>
                        <div className="carousel-item">
                          <img src={wifi2} className="d-block w-100" alt="..." style={{ borderRadius: 10 }}/>
                        </div>
                        <div className="carousel-item">
                          <img src={wifi3} className="d-block w-100" alt="..." style={{ borderRadius: 10 }}/>
                        </div>
                        <div className="carousel-item">
                          <img src={wifi4} className="d-block w-100" alt="..." style={{ borderRadius: 10 }}/>
                        </div>
                        <div className="carousel-item">
                          <img src={wifi5} className="d-block w-100" alt="..." style={{ borderRadius: 10 }}/>
                        </div>
                      </div>
                      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>
                      <div className="card-body text-center">
                        <h5 className="card-title">WiFi Device</h5>
                        <p className="card-text">Buy a Wifi Device for your stay in Ghana.</p>
                        <a href="/selfservice/wifi" className="btn btn-primary">More</a>
                      </div>
                    </div>
                </div>
                <div className="col-sm py-4">
                  <div className="card shadow service border-0" style={{ borderRadius: 10 }}>
                    <div id="carouselExampleAutoplaying2" className="carousel slide" data-bs-ride="carousel">
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <img src={powerBank1} className="d-block w-100" alt="..." style={{ borderRadius: 10 }}/>
                        </div>
                        <div className="carousel-item">
                          <img src={powerBank2} className="d-block w-100" alt="..." style={{ borderRadius: 10 }}/>
                        </div>
                        <div className="carousel-item">
                          <img src={powerBank3} className="d-block w-100" alt="..." style={{ borderRadius: 10 }}/>
                        </div>
                        <div className="carousel-item">
                          <img src={powerBank4} className="d-block w-100" alt="..." style={{ borderRadius: 10 }}/>
                        </div>
                        <div className="carousel-item">
                          <img src={powerBank5} className="d-block w-100" alt="..." style={{ borderRadius: 10 }}/>
                        </div>
                      </div>
                      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying2" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying2" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>
                    <div className="card-body text-center">
                      <h5 className="card-title">Power Bank</h5>
                      <p className="card-text">Buy a Power bank for your stay in Ghana.</p>
                      <a href="/selfservice/power-bank" className="btn btn-primary">More</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-5 packages" >
              <div className="container">
                  <h2 className="text-center" >Data Top-Up Packages</h2>
                  <div className="row mb-3">
                    {filteredProducts.map(product => (
                        <div key={product.id} className="col-sm-3 mb-3">
                            <div className="card mb-3 shadow product border-0" style={{ borderRadius: 10 }}>
                                        <img src={product.image} className="img-fluid p-2" alt={product.name} style={{ borderRadius: 10 }}/>
                                        <div className="card-body d-flex flex-column">
                                            <h6 className="card-title fw-bold text-center flex-grow-1">{product.name.replace(/\s\d+$/, '')}</h6>
                                            <p className="card-text text-center fw-bold">${(product.price).toFixed(2)}</p>
                                            <div className='text-center' >
                                                <Link to={{pathname: `/top-up-checkout/${product.id}`}} className="btn btn-outline-primary w-50 ">Buy</Link>
                                            </div>
                                        </div>
                            </div>
                        </div>
                    ))}
                  </div>
                  <div className="text-end">
                    <Link to={{pathname: `/top-up-data`}} className="btn btn-primary">More</Link>
                  </div>
              </div>
          </div>
          <div className="pricing " id="pricing">
            <div className="container">
            <h2 className="text-center" >Pricing Plan</h2>
              {/* <div className="row">
                    <div className="col-sm-3 py-4"  >
                      <div className="card" style={{border: "1px solid #00239F", borderRadius: 10 }}>
                        <div className="card-body">
                            <h3 className="text-center" >1 Week Plan</h3>
                            <p className="text-muted text-center" >Charge per day</p>
                            <div className="d-flex justify-content-center" >
                              <span className="text-muted" >$</span>
                              <span><h2>3.50</h2></span>
                            </div>
                            <div className="py-3" >
                              <p> <CheckCircleOutline sx={{ color: '#00239F' }}/> Number of countries</p>
                              <p> <CheckCircleOutline sx={{ color: '#00239F' }}/> Valid for 7 days</p>
                              <p> <CheckCircleOutline sx={{ color: '#00239F' }}/> Unlimited data</p>
                              <p> <CheckCircleOutline sx={{ color: '#00239F' }}/> Unlimited local & internet calls</p>
                            </div>
                            <div>
                              <a href="/selfservice/esim"><button className="btn btn-outline-primary w-100" >Buy Now</button> </a>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 py-4" >
                      <div className="card" style={{border: "1px solid #00239F", borderRadius: 10 }}>
                        <div className="card-body">
                          <h3 className="text-center" >2 Weeks Plan</h3>
                            <p className="text-muted text-center" >Charge per day</p>
                            <div className="d-flex justify-content-center" >
                              <span className="text-muted" >$</span>
                              <span><h2>3.00</h2></span>
                            </div>
                            <div className="py-3" >
                              <p> <CheckCircleOutline sx={{ color: '#00239F' }}/> Number of countries</p>
                              <p> <CheckCircleOutline sx={{ color: '#00239F' }}/> Valid for 14 days</p>
                              <p> <CheckCircleOutline sx={{ color: '#00239F' }}/> Unlimited data</p>
                              <p> <CheckCircleOutline sx={{ color: '#00239F' }}/> Unlimited local & internet calls</p>
                            </div>
                            <div>
                              <a href="/selfservice/esim"><button className="btn btn-outline-primary w-100" >Buy Now</button> </a>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 py-4"  >
                      <div className="card" style={{border: "1px solid #00239F", borderRadius: 10 }}>
                        <div className="card-body">
                            <h3 className="text-center" >3 Weeks Plan</h3>
                            <p className="text-muted text-center" >Charge per day</p>
                            <div className="d-flex justify-content-center" >
                              <span className="text-muted" >$</span>
                              <span><h2>2.50</h2></span>
                            </div>
                            <div className="py-3" >
                              <p> <CheckCircleOutline sx={{ color: '#00239F' }}/> Number of countries</p>
                              <p> <CheckCircleOutline sx={{ color: '#00239F' }}/> Valid for 21 days</p>
                              <p> <CheckCircleOutline sx={{ color: '#00239F' }}/> Unlimited data</p>
                              <p> <CheckCircleOutline sx={{ color: '#00239F' }}/> Unlimited local & internet calls</p>
                            </div>
                            <div>
                              <a href="/selfservice/esim"><button className="btn btn-outline-primary w-100" >Buy Now</button> </a>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 py-4" >
                      <div className="card" style={{border: "1px solid #00239F", borderRadius: 10 }}>
                        <div className="card-body">
                          <h3 className="text-center" >4 Weeks Plan</h3>
                          <p className="text-muted text-center" >Charge per day</p>
                          <div className="d-flex justify-content-center" >
                            <span className="text-muted" >$</span>
                            <span><h2>1.70</h2></span>
                          </div>
                          <div className="py-3" >
                            <p> <CheckCircleOutline sx={{ color: '#00239F' }}/> Number of countries</p>
                            <p> <CheckCircleOutline sx={{ color: '#00239F' }}/> Valid for 28 days onwards</p>
                            <p> <CheckCircleOutline sx={{ color: '#00239F' }}/> Unlimited data</p>
                            <p> <CheckCircleOutline sx={{ color: '#00239F' }}/> Unlimited local & internet calls</p>
                          </div>
                          <div>
                            <a href="/selfservice/esim"><button className="btn btn-outline-primary w-100" >Buy Now</button> </a>
                          </div>
                        </div>
                      </div>
                    </div>
              </div> */}
              <div className="row">
                {[
                  { plan: "1 Week Plan", price: "3.50", days: "7" },
                  { plan: "2 Weeks Plan", price: "3.00", days: "14" },
                  { plan: "3 Weeks Plan", price: "2.50", days: "21" },
                  { plan: "4 Weeks Plan", price: "1.70", days: "28 onwards" }
                ].map((item, index) => (
                  <div key={index} className="col-sm-3 py-4">
                    <div className="card h-100" style={{ border: "1px solid #00239F", borderRadius: 10 }}>
                      <div className="card-body d-flex flex-column">
                        <h3 className="text-center">{item.plan}</h3>
                        <p className="text-muted text-center">Charge per day</p>
                        <div className="d-flex justify-content-center">
                          <span className="text-muted">$</span>
                          <span><h2>{item.price}</h2></span>
                        </div>
                        <div className="py-3">
                          <p><CheckCircleOutline sx={{ color: '#00239F' }} /> Number of countries</p>
                          <p><CheckCircleOutline sx={{ color: '#00239F' }} /> Valid for {item.days} days</p>
                          <p><CheckCircleOutline sx={{ color: '#00239F' }} /> Unlimited data</p>
                          <p><CheckCircleOutline sx={{ color: '#00239F' }} /> Unlimited local & internet calls</p>
                        </div>
                        <div className="mt-auto">
                          <a href="/selfservice/esim">
                            <button className="btn btn-outline-primary w-100">Buy Now</button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

            </div>
            
          </div>
          <div className="about mt-5" id="about">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-sm">
                  <img src={rentimg} alt="rent" className="img-fluid"  />
                </div>
                <div className="col-sm">
                  <div className="mb-4">
                    <h3 className="">Mission Statement</h3>
                    <h5>Our mission is to provide high quality customer service to our clients.</h5>
                  </div>
                  <div className="mb-4">
                    <h3 className="">Vision Statement</h3>
                    <h5>Provide easy and fast connection to travelers on arrival in Ghana.</h5>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          <div className="reviews mt-5">
            <div className="container">
              <h2 className="text-center" >Customer Reviews</h2>
              <div className="row">
                <div className="col-sm-6">
                  <video style={{width: '-webkit-fill-available'}} src={customerReviews} controls ></video>
                </div>
                <div className="col-sm-6">
                  <video style={{width: '-webkit-fill-available'}} src={customerReviews2} controls ></video>
                </div>
              </div>
              <div className="text-end">
              <a href="https://www.youtube.com/@RentsimConnect" target="_blank" className="btn btn-primary" rel="noreferrer">View More</a>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
       
        
    </div>
  );
};
