import React, { useContext, useEffect, useState } from 'react';
import NavBars from "../components/NavBars";
import { AuthContext } from '../context/AuthContext';
import { Backdrop, CircularProgress } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { Footer } from "../components/Footer";
import { ArrowLeft, ArrowRight } from '@mui/icons-material';

function Esim() {
    const token = sessionStorage.getItem('token');
    const { productData } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const [showNoProductFound, setShowNoProductFound] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 8;
    
    const products = productData;
    const filteredProducts = products.filter(product => product.name.toLowerCase().includes("e-sim"));
    const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);
    
    let navigate = useNavigate();

    useEffect(() => {
        let timeoutId;
    
        if (filteredProducts.length === 0) {
          timeoutId = setTimeout(() => {
            setIsLoading(false);
            setShowNoProductFound(true);
          }, 3000);
        } else {
          setIsLoading(false);
        }
    
        return () => clearTimeout(timeoutId);
    }, [filteredProducts]);

    function getPathName() {
        localStorage.setItem("pathName", window.location.pathname);
        navigate("/register");
    }

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredProducts.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <div>
            <NavBars />
            <div className='container mt-5'>
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <h2>E-Sim</h2>
                {!isLoading && showNoProductFound && <h4 className='text-center'>No product found</h4>}
                <div className="row">
                    {currentItems.map(product => (
                        // <div key={product.id} className="col-sm-3">
                        //     <div className="card mb-3 shadow product border-0">
                        //         <div>
                        //             <img src={product.image} className="img-fluid p-2" alt={product.name} />
                        //             <div className="card-body">
                        //                 <h6 className="card-title fw-bold text-center">{product.name.replace(/\s\d+$/, '')}</h6>
                        //                 <p className="card-text text-center">{product.description}</p>
                        //                 <p className="card-text text-center fw-bold">${(product.price * product.duration).toFixed(2)}</p>
                        //                 {token ? (
                        //                     <div className='text-center'>
                        //                         <Link to={`/checkout/${product.id}`} className="btn btn-outline-primary w-50">Buy</Link>
                        //                     </div>
                        //                 ) : (
                        //                     <div className='text-center'>
                        //                         <button onClick={getPathName} className="btn btn-outline-primary w-50">Buy</button>
                        //                     </div>
                        //                 )}
                        //             </div>
                        //         </div>
                        //     </div>
                        // </div>
                        <div key={product.id} className="col-sm-3 mb-3">
                        <div className="card mb-3 shadow product border-0">
                            <img src={product.image} className="img-fluid p-2" alt={product.name} />
                            <div className="card-body d-flex flex-column">
                                <h6 className="card-title fw-bold text-center">{product.name.replace(/\s\d+$/, '')}</h6>
                                <p className="card-text text-center flex-grow-1">{product.description}</p>
                                <p className="card-text text-center fw-bold">${(product.price * product.duration).toFixed(2)}</p>
                                <div className="text-center">
                                    {token ? (
                                        <Link to={`/checkout/${product.id}`} className="btn btn-outline-primary w-50">Buy</Link>
                                    ) : (
                                        <button onClick={getPathName} className="btn btn-outline-primary w-50">Buy</button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    ))}
                </div>

                {/* Pagination Controls */}
                {filteredProducts.length > itemsPerPage && (
                    <div className="d-flex justify-content-center mt-4">
                        <button className="btn btn-outline-primary mx-2" onClick={handlePrevious} disabled={currentPage === 1}><ArrowLeft/></button>
                        <button className="btn btn-outline-primary mx-2" onClick={handleNext} disabled={currentPage === totalPages}><ArrowRight/></button>
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
}

export default Esim;