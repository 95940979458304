import React, { useContext, useEffect, useState } from 'react';
import NavBars from "../components/NavBars";
import { AuthContext } from '../context/AuthContext';
import { Backdrop, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { Footer } from "../components/Footer";
import { ArrowLeft, ArrowRight } from '@mui/icons-material';

function TopUpPackage() {
    const { productData } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 8;
    
    const products = productData;
    const filteredProducts = products.filter(product => product.name.toLowerCase().includes("data top-up"));

    useEffect(() => {
        let timeoutId;
    
        if (filteredProducts.length === 0) {
          timeoutId = setTimeout(() => {
            setIsLoading(false);
          }, 3000);
        } else {
          setIsLoading(false);
        }
    
        return () => clearTimeout(timeoutId); 
    }, [filteredProducts]);

    // Pagination logic
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

    const nextPage = () => {
        if (indexOfLastProduct < filteredProducts.length) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    return (
        <div>
            <NavBars />
            <div className='container mt-5'>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <h2>Data Top-Up Packages</h2>
                <div className="row">
                    {currentProducts.length > 0 ? (
                        currentProducts.map(product => (
                            <div key={product.id} className="col-sm-3 mb-3">
                                <div className="card mb-3 shadow product border-0">
                                    <img src={product.image} className="img-fluid p-2" alt={product.name} />
                                    <div className="card-body d-flex flex-column">
                                        <h6 className="card-title fw-bold text-center flex-grow-1">
                                            {product.name.replace(/\s\d+$/, '')}
                                        </h6>
                                        <p className="card-text text-center fw-bold">${product.price.toFixed(2)}</p>
                                        <div className='text-center'>
                                            <Link to={{ pathname: `/top-up-checkout/${product.id}` }} className="btn btn-outline-primary w-50">
                                                Buy
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="col-12 text-center">
                            <p className="fw-bold">No Package found</p>
                        </div>
                    )}
                </div>
                {/* Pagination Buttons */}
                {filteredProducts.length > productsPerPage && (
                    <div className="d-flex justify-content-center mt-4 mb-4">
                        <button className="btn btn-primary mx-2" onClick={prevPage} disabled={currentPage === 1}>
                           <ArrowLeft/>
                        </button>
                        <button className="btn btn-primary mx-2" onClick={nextPage} disabled={indexOfLastProduct >= filteredProducts.length}>
                            <ArrowRight/>
                        </button>
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
}

export default TopUpPackage;