import React, {useState, useContext, useEffect} from "react";
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import {AuthContext} from '../context/AuthContext';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ToastService from "react-material-toast";
import { usePaystackPayment } from 'react-paystack';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import bg from "../img/bg2.png";
import NavBars from "../components/NavBars";
import axios from "axios";
import { useParams } from 'react-router-dom';

const toast = ToastService.new({
    place: "topRight",
    duration: 1,
    maxCount: 1,
  });

function TopUpCheckout() {
    const { id } = useParams();
    const [customer_name, setName] = useState("");
    const [customer_email, setEmail] = useState("");
    const [customer_phone_number, setPhoneNumber] = useState("");
    const [dataPackage, setPackage] = useState("");
    const [paymentMode, setPaymentMode] = useState("");
    const [ref, setTransRef] = useState("");
    const [status, setPaymentStatus] = useState("");
    const {onTopUp, isLoading, baseURL, publicKey, secretKey, hiddenButtonRef, cashEnabled} = useContext(AuthContext);
    const [open, setOpen] = useState(false);
    const [cash, setCash] = useState(false);
    const [rates, setRates] = useState([]);
    const [data, setData] = useState([]);

    const getProductById = async (id) => {
        try {
            const response = await axios.get(`${baseURL}/restsimproduct/${id}`,{
                headers:{
                    "Content-Type": "application/json",
                    Accept: "application/json",
                }
            });
            if (response.status === 200){
                let data = response.data
                setData(response.data);
                setPackage(data.name)
            }
        } catch (error) {
            console.log(error);
        }
    }
      

//Modal
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
      setOpen(false);
  };

  const handleClickCash = () => {
    setCash(true);
  };

  const handleCloseCash = () => {
      setCash(false);
  };

//Modal

  const handlePaymentMethod = (event) => {
    setPaymentMode(event.target.value);
  };

    // currency convertor function
  const getExchangeRates = async () => {
    await fetch("https://v6.exchangerate-api.com/v6/3dbf859c5b891497ef1e519e/pair/GHS/USD", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setRates(res.conversion_rate);
      })
      .catch((err) => {
        console.log(err);
      });
  };


   // other variables
  let payment_method = paymentMode;
  let amount_GHS = 0.0;
  let payment_amount = data.price;
  let payment_status = status;
  let trans_ref = ref;

    amount_GHS = (data.price / rates).toFixed(2);
    let finalAmount = amount_GHS * 100;
    
  useEffect(() => {
    getExchangeRates();
    getProductById(id)
    //eslint-disable-next-line
  }, []);

  
  function afterPayment() {
    // event.preventDefault();
    if(customer_name === '' || customer_email === '' || paymentMode === '' || customer_phone_number === ''){
        toast.error("All fields are required!");
    }else{
        onTopUp(
            customer_name,
            customer_email,
            customer_phone_number,
            dataPackage,
            payment_method,
            parseFloat(payment_amount),
            payment_status,
            trans_ref,
            // event
        );
    }
  }


   //Paystack
    const config = {
      reference: (new Date()).getTime().toString(),
      email: customer_email,
      amount: finalAmount, 
      publicKey: publicKey,
      secretKey: secretKey,
      currency: 'GHS',
    };

  

  const onSuccess = (reference) => {
    setTransRef(reference.reference);
    setPaymentStatus(reference.status);
    handleClose();
  };

  const onClose = () => {

    console.log('closed')
  }

  const initializePayment = usePaystackPayment(config);
  return (
    <div>
        <NavBars/>
        <div className="main-container">
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        
        <header className="mt-5 spacerOB" >
        </header>
        <div className="form-container" style={{maxWidth: "400px"}}>
            <form>
                <TextField
                    type='text'
                    label="Name"
                    placeholder='Name'
                    fullWidth
                    required
                    className='mb-3'
                    value={customer_name}
                    onChange={(e) => setName(e.target.value)}
                    
                />
                <TextField
                    type='customer_email'
                    label="Email Address"
                    placeholder='Email Address'
                    variant="outlined"
                    fullWidth
                    required
                    className='mb-3'
                    value={customer_email}
                    onChange={(e) => setEmail(e.target.value)}
                    
                />
                <div className="mb-3">
                    <FormLabel >Phone Number</FormLabel>
                    <PhoneInput
                        placeholder="Phone Number"
                        defaultCountry="GH"
                        className="phone-input"
                        value={customer_phone_number}
                        onChange={setPhoneNumber}
                        limitMaxLength
                    />
                </div>
                <TextField
                    type='text'
                    label="Package"
                    fullWidth
                    required
                    className='mb-3'
                    value={dataPackage}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                
                <div className="input-filed">
                    <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label" >Select Method of Payment</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={paymentMode}
                            label="Select Method of Payment"
                            onChange={handlePaymentMethod}
                            >
                            <MenuItem value="Card" onClick={handleClickOpen} >Card</MenuItem>
                            <MenuItem value="Mobile Money" onClick={handleClickOpen}>Mobile Money</MenuItem>
                            {cashEnabled && (
                            <MenuItem value="Cash" onClick={handleClickCash}>
                                Cash
                            </MenuItem>
                            )}

                            </Select>
                        </FormControl>
                </div>
            </form>
            <div className="input-filed subBtn">
                <button type="submit" className="btn btn-primary w-100" onClick={(e) => {afterPayment(e)}} >Submit</button>
            </div>
                <button className="btn btn-success" hidden ref={hiddenButtonRef}  type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Success</button>
        </div>
        <div className="watermark">
            <img src={bg} alt="watermark" />
        </div>
        {/* Modal  */}
                <Dialog
                    open={open}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            onClose(event, reason)
                        }
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableEscapeKeyDown = {true}
                >
                    <DialogTitle id="alert-dialog-title">
                    {"Confirm to Make Payment"}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                       Amount in Dollars: <span className="amount" > ${data.price} </span> 
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Amount in Cedis: <span className="amount" > GH₵{amount_GHS} </span> 
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => {
                        setPaymentMode("");
                        handleClose();
                    }}>Cancel</Button>
                    <Button onClick={()=>{initializePayment(onSuccess, onClose)}} autoFocus>
                        Make Payment
                    </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={cash}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            onClose(event, reason)
                        }
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableEscapeKeyDown = {true}
                >
                    <DialogTitle id="alert-dialog-title">
                    {"Confirm to Make Payment"}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                       Amount in Dollars: <span className="amount" > ${data.price} </span> 
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Amount in Cedis: <span className="amount" > GH₵{amount_GHS} </span> 
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => {
                        setPaymentMode("");
                        handleCloseCash();
                    }}>Cancel</Button>
                    <Button onClick={()=>{
                        setPaymentMode("Cash");
                        handleCloseCash();
                    }} autoFocus>
                        OK
                    </Button>
                    </DialogActions>
                </Dialog>

        </div>

        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="staticBackdropLabel">Transaction Successful</h1>
                </div>
                <div className="modal-body">
                    <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={isLoading}
                        >
                            <CircularProgress color="inherit" />
                    </Backdrop>
                    <h5 className="text-center">Processed... Your data will be delivered within 5- 45 minutes. 🤝🏾</h5>
                    <h5 className="text-center">Please kindly notify me after 45 minutes if you still haven't received it. Thank you</h5>
                    
                </div>
                <div className="modal-footer">
                    <a href="/" type="button" className="btn btn-danger">Dismis</a>
                </div>
                </div>
            </div>
        </div>
    </div>
    
  )
}

export default TopUpCheckout